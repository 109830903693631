export default [
  { path: '', component: () => import('layouts/App'), children: [
    { path: '', component: () => import('layouts/auth/Index'), children: [
      { path: '', name: 'login', icon: 'genderless', component: () => import('pages/login/Index'), },
    ], },
    { path: '', component: () => import('layouts/main/Index'), children: [
      { path: '/companies', name: 'companies', title: 'Компании', icon: 'genderless', component: () => import('pages/companies/Index'), },
      { path: '/excel', name: 'excel', title: 'Выгрузка', icon: 'genderless', component: () => import('pages/excel/Index'), },
      { path: '/companies/:companyId/departments/:id', name: 'department', title: 'Департамент', icon: 'genderless', component: () => import('pages/department/Index'), },
      { path: '/settings', name: 'settings', title: 'Переменные', icon: 'genderless', component: () => import('pages/settings/Index'), },
      { path: '/company/:id', name: 'company', title: 'Компания', icon: 'genderless', component: () => import('pages/company/Index'), },
      { path: '/stats', name: 'stats', title: 'Статистика приложения', icon: 'genderless', component: () => import('pages/app-stats/Index'), },
      { path: '/applications', name: 'applications', title: 'Заявки тех поддержки', icon: 'genderless', component: () => import('pages/applications/Index'), },
      { path: '/faqs', name: 'faqs', title: 'FAQ', icon: 'genderless', component: () => import('pages/faqs/Index'), },
      { path: '/users', name: 'users', title: 'Пользователи', icon: 'genderless', component: () => import('pages/users/Index'), },
      { path: '/users/:id', name: 'user', title: 'Пользователь', icon: 'genderless', component: () => import('pages/user/Index'), },
      { path: '/password', name: 'password', title: 'Пользователи', icon: 'genderless', component: () => import('pages/password/Index'), },
      { path: '/promotions', name: 'promotions', title: 'Акции', icon: 'genderless', component: () => import('pages/promotions/Index'), },
      { path: '/logs', name: 'logs', title: 'Логи', icon: 'genderless', component: () => import('pages/logs/Index'), },
      { path: '/promotions/:id', name: 'promotion', title: 'Акция', icon: 'genderless', component: () => import('pages/promotion/Index'), },
      { path: '/notifications', name: 'notifications', title: 'Уведомления', icon: 'genderless', component: () => import('pages/notifications/Index'), },
      { path: '/notifications/create', name: 'notificationsCreate', title: 'Создание уведомления', icon: 'genderless', component: () => import('pages/notifications-create/Index'), },
    ], },
  ], },
]
