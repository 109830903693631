/**
 *
 */
export default class RestApi {
  auth = {
    login: (data) => $app.api.post([ 'auth/login', ]).data(data),
    loginPin: (userId, data) => $app.api.post([ 'auth/login/users*/pin', userId, ]).data(data),
    register: (iin, phone, data) => $app.api.post([ 'auth/register**', iin, phone, ]).data(data),
    recover: (iin, data) => $app.api.post([ 'auth/recover*', iin, ]).data(data),
    info: () => $app.api.get([ 'auth/info', ]),
    checkData: (data) => $app.api.post([ 'auth/check-data', ]).data(data),
    sendCode: (iin, phone) => $app.api.post([ 'sms/send-code**', iin, phone, ]),
    checkCode: (iin, data) => $app.api.post([ 'sms/check-code*', iin, ]).data(data),
    deleteAcc: (id) => $app.api.patch([ 'auth/delete/users*', id, ]),
    checkIin: (iin) => $app.api.post([ 'iin-check*', iin, ]),
  }

  promotions = {
    get: (id) => $app.api.get([ 'promotions*', id, ]),
    edit: (id, data) => $app.api.patch([ 'promotions*', id, ]).data(data),
    create: (data) => $app.api.post([ 'promotions', ]).data(data),
    give: (id, data) => $app.api.post([ 'promotions*/give', id, ]).data(data),
    delete: (id) => $app.api.delete([ 'promotions*', id, ]),
    removeFromUser: (id, userId) => $app.api.post([ 'promotions*/users*', id, userId, ]),
  }

  settings = {
    get: (id) => $app.api.get([ 'settings*', id, ]),
    edit: (id, data) => $app.api.patch([ 'settings*', id, ]).data(data),
  }

  stats = {
    get: (query) => $app.api.get([ 'prosper/stats*', query, ]),
  }

  companies = {
    get: (id) => $app.api.get([ 'companies*', id, ]),
    excel: (id, data) => $app.api.post([ 'companies*/excel', id, ]).data(data),
    edit: (id, data) => $app.api.patch([ 'companies*', id, ]).data(data),
    pitech: (id, data) => $app.api.post([ 'companies*/pitech', id, ]).data(data),
    pitechExcel: (id, data) => $app.api.post([ 'companies*/pitech/excel', id, ]).data(data),
    create: (data) => $app.api.post([ 'companies', ]).data(data),
  }
  
  visits = {
    get: (id) => $app.api.get([ 'companies*/visits', id, ]),
  }

  lang = {
    locales: (lang) => $app.api.get([ 'locales', ]),
    items: () => $app.api.get([ 'translations/content', ]),
  }

  users = {
    get: (id) => $app.api.get([ 'users*', id, ]),
    getCompany: (id) => $app.api.get([ 'users-companies*', id, ]),
    getByCompany: (companyId) => $app.api.get([ 'companies*/users', companyId, ]),
    edit: (id, data) => $app.api.patch([ 'users*', id, ]).data(data),
    editPassword: (id, data) => $app.api.patch([ 'users*/password', id, ]).data(data),
    sendCode: (id) => $app.api.post([ 'users*/send-code', id, ]),
    checkCode: (id, data) => $app.api.post([ 'users*/check-code', id, ]).data(data),
    editPin: (id, data) => $app.api.patch([ 'users*/edit-pin', id, ]).data(data),
    editLimit: (id, data) => $app.api.patch([ 'users*/limit', id, ]).data(data),
    getFree: (companyId) => $app.api.get([ 'companies*/users/free', companyId, ]),
    recover: (userId) => $app.api.patch([ 'users*/recover', userId, ]),
  }
  
  branches = {
    get: (companyId, id) => $app.api.get([ 'companies*/branches*', companyId, id, ]),
    create: (companyId, data) => $app.api.post([ 'companies*/branches', companyId, ]).data(data),
    edit: (companyId, id, data) => $app.api.patch([ 'companies*/branches*', companyId, id, ]).data(data),
    addUsers: (companyId, id, data) => $app.api.post([ 'companies*/branches*/add', companyId, id, ]).data(data),
    removeUser: (companyId, id, userId) => $app.api.post([ 'companies*/branches*/users*/remove', companyId, id, userId, ]),
    delete: (companyId, id) => $app.api.delete([ 'companies*/branches*', companyId, id, ]),
  }

  notifications = {
    get: (id) => $app.api.get([ 'notifications*', id, ]),
    send: (data) => $app.api.post([ 'notifications', ]).data(data),
  }

  transactions = {
    get: (companyId) => $app.api.get([ 'companies*/transactions', companyId, ]),
    check: (companyId, userId, data) => $app.api.post([ 'companies*/users*/transactions/check', companyId, userId, ]).data(data),
    create: (companyId, userId, data) => $app.api.post([ 'companies*/users*/transactions', companyId, userId, ]).data(data),
    getList: (companyId, userId, request = '') => $app.api.get([ 'companies*/users*/transactions'+request, companyId, userId, ]),
  }

  contacts = {
    get: () => $app.api.get([ 'contacts', ]),
  }

  application = {
    getByUser: (userId) => $app.api.get([ 'applications/users*', userId, ]),
    get: (id) => $app.api.get([ 'applications', id, ]),
    create: (data) => $app.api.post([ 'applications', ]).data(data),
    edit: (id, data) => $app.api.patch([ 'applications*', id, ]).data(data),
  }

  faqs = {
    get: (id) => $app.api.get([ 'faqs*', id, ]),
    create: (data) => $app.api.post([ 'faqs', ]).data(data),
    edit: (id, data) => $app.api.patch([ 'faqs*', id, ]).data(data),
  }

  logs = {
    get: () => $app.api.get([ 'logs', ]),
  }
}
