<template>
  <div class="c-create-department">
    <div class="department-window">
      <n-card class="department-card">
        <n-loader :loading="$var('load')" />
        <div class="card-top">
          <n-button flat @click="closeModal">X</n-button>
        </div>
        <div>
          <div class="department-info">
            <n-items>
              <n-input title="Название департамента" v-bind="$form.input('title')"></n-input>
              <n-input title="Лимит на снятие (%)" v-bind="$form.input('limit')"></n-input>
            </n-items>
          </div>
          <div class="button-save">
            <n-button color="primary" @click="save">Сохранить</n-button>
          </div>
        </div>
      </n-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CCreateDepartment',
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  created() {
    this.$form.init({
      title: '',
      limit: 50,
    })
    this.$form.rules({
      title: [ 'required', ],
    })
  },
  methods: {
    closeModal() {
      this.$emit('update:show', false)
    },
    save() {
      console.log(this.$form.get())
      if (this.$form.check()) {
        this.$var('load', true)
        $api.branches.create(this.id, this.$form.get()).then(() => {
          this.closeModal()
        }).finally(() => {
          this.closeModal()
          this.$var('load', false)
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.c-create-department {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, .2);

  .button-save {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: right;
  }
  .card-top {
    display: flex;
    align-items: center;
    justify-content: right;
  }
}
</style>
